<template>
	<main class="py-0">
		<section id="info">
			<div class="grid grid-cols-4 gap-x-5 gap-y-10">
				<div>
					<h5>Startdatum</h5>
					<p>{{ formatDateString(activity.starting_at) }}</p>
				</div>

				<div>
					<h5>Sista anmälningsdatum</h5>
					<p>{{ formatDateString(activity.last_application_at) }}</p>
				</div>

				<div>
					<h5>Verksamhetsform</h5>
					<p>{{ activity.operation.title }}</p>
				</div>

				<div>
					<h5>Reservlista används</h5>
					<p>{{ activity.use_spare_applications ? 'Ja' : 'Nej' }}</p>
				</div>

				<div>
					<h5>Minsta antal deltagare</h5>
					<p>{{ activity.min_applications }}</p>
				</div>

				<div>
					<h5>Max antal deltagare</h5>
					<p>{{ activity.max_applications }}</p>
				</div>

				<div>
					<h5>Antagna deltagare</h5>
					<p>{{ activity.verified_applications }}</p>
				</div>

				<div>
					<h5>Antal anmälda</h5>
					<p>{{ activity.total_applications }}</p>
				</div>
			</div>
		</section>

		<section id="description">
			<h3>Beskrivning</h3>
			<p v-html="activity.description" class="w-1/2"></p>
		</section>

		<section id="prices">
			<h3>Priser</h3>
			<table>
				<tr>
					<th class="w-1/4">Avgift</th>
					<th class="w-1/4">Kostnad</th>
					<th class="w-1/4">Gäller fr.o.m</th>
					<th class="w-1/4"></th>
				</tr>
				<tr v-for="price in activity.prices" :key="price.id">
					<td>{{ price.title }}</td>
					<td>{{ roundUpNumbers(price.amount) }} kr</td>
					<td>{{ formatDateString(price.starting_at) }}</td>
					<td></td>
				</tr>
			</table>
		</section>

		<section id="contacts">
			<h3>Kontaktpersoner</h3>
			<table>
				<tr>
					<th class="w-1/4">Namn</th>
					<th class="w-1/4">E-post</th>
					<th class="w-1/4">Telefonnummer</th>
					<th class="w-1/4">Roll</th>
				</tr>
				<tr v-for="user in activity.users" :key="user.id">
					<td>{{ user.first_name }} {{ user.last_name }}</td>
					<td>
						<a :href="`mailto:${user.email}`" class="text-primary underline">
							{{ user.email }}
						</a>
					</td>
					<td>
						<a :href="`phone:${user.telephone}`" class="text-primary underline">
							{{ user.telephone }}
						</a>
					</td>
					<td>{{ user.role.title }}</td>
				</tr>
			</table>
		</section>

		<section id="food-lodging">
			<h3>Kost</h3>
			<p v-html="activity.food" class="w-1/2 mb-10"></p>

			<h3>Logi</h3>
			<p v-html="activity.lodging" class="w-1/2"></p>
		</section>

		<section id="address">
			<table>
				<tr>
					<th class="w-1/4">Adress</th>
					<th class="w-1/4">Postnummer</th>
					<th class="w-1/4">Stad</th>
					<th class="w-1/4"></th>
				</tr>
				<tr>
					<td>{{ activity.address }}</td>
					<td>{{ activity.zipcode }}</td>
					<td>{{ activity.city }}</td>
					<td></td>
				</tr>
			</table>
		</section>
	</main>
</template>

<script>
import {formatDateString} from '@/core/helpers/date'
import {roundUpNumbers} from '@/core/helpers/numbers'

export default {
	name: 'ActivityGeneral',
	computed: {
		activity() {
			return this.$store.getters['ActivityStore/activity']
		},
	},
	methods: {
		formatDateString,
		roundUpNumbers,
	},
}
</script>

<style scoped>
#description,
#contacts,
#address {
	@apply bg-white;
}

h3 {
	@apply mb-5;
}
</style>
